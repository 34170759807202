import * as React from "react"
import {useState} from "react"
import {Box, Button, IconButton, Modal} from "@mui/joy";
import SettingsIcon from "@mui/icons-material/Settings";
import PlanBuilderDialog from "./PlanBuilderDialog";
import ProfileDialog from "./ProfileDialog";
import {PlanBuilderDialogLoading, ProfileDialogLoading} from "./Loading";
import {useTranslation} from "react-i18next";

export const AgendaDayViewFooter = () => {
    const {t} = useTranslation("navigation");
    const [openPlanBuilder, setOpenPlanBuilder] = useState<boolean>(false);
    const [openProfile, setOpenProfile] = useState<boolean>(false);
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <Box>
                <Button onClick={() => setOpenPlanBuilder(true)}>{t("button__create_plan")}</Button>
                <Modal
                    aria-labelledby="close-modal-title"
                    open={openPlanBuilder}
                    onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                        setOpenPlanBuilder(false);
                    }}
                >
                    <React.Suspense fallback={<PlanBuilderDialogLoading/>}>
                        <PlanBuilderDialog close={() => setOpenPlanBuilder(false)}/>
                    </React.Suspense>
                </Modal>
            </Box>
            <Box>
                <IconButton variant="plain" color="neutral" onClick={() => setOpenProfile(true)}>
                    <SettingsIcon/>
                </IconButton>
                <Modal
                    aria-labelledby="close-modal-title"
                    open={openProfile}
                    onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                        setOpenProfile(false);
                    }}
                >
                    <React.Suspense fallback={<ProfileDialogLoading screen="mobile"/>}>
                        <ProfileDialog screen="mobile"/>
                    </React.Suspense>
                </Modal>
            </Box>
        </Box>
    )
}

export default AgendaDayViewFooter
