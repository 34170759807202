import {Fragment, useState} from "react"
import {Field, Form, FormSpy} from "react-final-form"
import {add, format, getISODay, parseISO, sub, min} from "date-fns"
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Input,
    List,
    ListDivider,
    ListItem,
    ListItemDecorator,
    listItemDecoratorClasses,
    ModalClose,
    ModalDialog,
    ModalOverflow,
    Option,
    optionClasses,
    Select,
    Typography
} from "@mui/joy"
import SwimmingIcon from "@mui/icons-material/Pool"
import CyclingIcon from "@mui/icons-material/DirectionsBike"
import RunningIcon from "@mui/icons-material/DirectionsRun"
import {Check, KeyboardArrowRight} from "@mui/icons-material"
import {Backdrop, CircularProgress} from "@mui/material"
import {CreateEvent, useCreateEventMutation} from "../app/services/eventApi"
import JoyDatePicker from "./JoyDatePicker"
import {useGetPlanListQuery} from "../app/services/planApi";
import {PlanBuilderDialogLoading} from "./Loading";
import {useTranslation} from "react-i18next";


const validate = (values: CreateEvent) => {
    const errors: Partial<Record<keyof CreateEvent, string>> = {}
    if (!values.title) {
        errors.title = "form_validation__title_empty"
    }
    if (!values.dateFrom) {
        errors.dateFrom = "form_validation__date_from_empty"
    }
    if (!values.dateTo) {
        errors.dateTo = "form_validation__date_to_empty"
    }
    // if (!values.planId) {
    //     errors.planId = "form_validation__event_type_empty"
    // }
    return errors
}
const PlanBuilderDialog = ({close}: { close: () => void }) => {
    const {t, i18n} = useTranslation("planbuilder");
    const lang = i18n.language
    const initialDate = new Date();
    const [saving, setSaving] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<Date | null>(initialDate);
    const [planId, setPlanId] = useState<string | null>(null);
    const {data, error, isLoading} = useGetPlanListQuery();
    const [createEvent] = useCreateEventMutation()
    if (isLoading) {
        return <PlanBuilderDialogLoading/>
    }
    if (error) {
        return <div>error...</div>
    }
    const planById: { [id: string]: any } = {}
    const plansByType: any[] = []
    const plansByCategory: any[] = []
    data!.forEach((i: any) => {
        planById[i.id] = i
        plansByType[i.planType] ||= []
        plansByType[i.planType].push(i);
        plansByCategory[i.planCategory] ||= []
        plansByCategory[i.planCategory].push(i);
    });
    const plan = planId ? planById[planId] : null;
    const minDate = dateFrom && plan ? add(dateFrom, {weeks: plan.minWeeks}) : null
    const maxDate = dateFrom && plan ? add(dateFrom, {weeks: plan.maxWeeks}) : null
    const isInvalidDateTo = (date: Date) => {
        if (minDate && date < minDate) {
            return true;
        }
        if (maxDate && date > maxDate) {
            return true;
        }
        return getISODay(date) < 5;
    };
    const variants = [add(dateFrom ? dateFrom : new Date(), {months: 3})];
    if(maxDate) {
        variants.push(maxDate)
    }
    let closestAvailableDateIn3Months = min(variants);
    while (isInvalidDateTo(closestAvailableDateIn3Months)) {
        closestAvailableDateIn3Months = sub(closestAvailableDateIn3Months, {days: 1})
    }
    const offseasonInitialValues = {
        dateFrom: format(initialDate, "yyyy-MM-dd"),
        includeTest: true,
        priority: "A",
        calendarSlots: [
            {sportType: "SWIMMING", dow: 2},
            {sportType: "RUNNING", dow: 3},
            {sportType: "CYCLING", dow: 4},
            {sportType: "SWIMMING", dow: 5},
            {sportType: "RUNNING", dow: 6},
            {sportType: "CYCLING", dow: 7},
        ],
        dateTo: format(closestAvailableDateIn3Months, "yyyy-MM-dd"),
    } as Partial<CreateEvent>
    const defaultInitialValues = {
        dateFrom: format(initialDate, "yyyy-MM-dd"),
        includeTest: true,
        priority: "A",
        calendarSlots: [
            {sportType: "SWIMMING", dow: 2},
            {sportType: "SWIMMING", dow: 5},
            {sportType: "RUNNING", dow: 3},
            {sportType: "RUNNING", dow: 6},
            {sportType: "CYCLING", dow: 4},
            {sportType: "CYCLING", dow: 7},
            {sportType: "GYM", dow: 4}
        ],
        dateTo: format(closestAvailableDateIn3Months, "yyyy-MM-dd"),
    } as Partial<CreateEvent>
    console.log(plan)
    const initialValues = plan && plan.id === '5d1862e0-e52a-4d0c-a918-4a81e197986a' ? offseasonInitialValues : defaultInitialValues
    console.log(initialValues)
    const save = async (event: CreateEvent) => {
        setSaving(true)
        try {
            await createEvent(event).unwrap()
            setSaving(false)
            close()
        } catch (error) {
            console.error(error)
            alert("Something went wrong")
        }
    }
    // @ts-ignore
    return (
        <ModalOverflow>
            <ModalDialog
                layout="fullscreen"
                color="neutral"
                size="lg"
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <ModalClose/>
                <Typography
                    id="alert-dialog-modal-title"
                    component="h2"
                    startDecorator={
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 0.5
                        }}>
                            <SwimmingIcon/>
                            <CyclingIcon/>
                            <RunningIcon/>
                        </Box>
                    }
                >
                    {t("dialog_title")}
                </Typography>
                <Divider/>
                <Form<CreateEvent>
                    keepDirtyOnReinitialize
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={save}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Box sx={{
                                display: "flex",
                                alignItems: {md: "center"},
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                                pt: {xs: 4, md: 0},
                                pb: {md: 20}
                            }}
                            >
                                <Box sx={{my: 2, width: {xs: "100%", md: "50vw"}}}>
                                    <Box
                                        sx={{
                                            width: {sm: "100%", md: "50vw"},
                                            display: "grid",
                                            alignItems: "center",
                                            justifyItems: "space-between",
                                            gridTemplateColumns: {
                                                sm: "1fr",
                                                // md: "4fr 1fr" TODO uncomment for priority
                                            },
                                            // gridTemplateRows: {
                                            //     sm: "2fr",
                                            //     md: "1fr"
                                            // },
                                            gap: 4
                                        }}
                                    >
                                        <Field name="title">
                                            {({input, meta}) => (
                                                <FormControl size="lg">
                                                    <FormLabel>{t("form_field__title_label")}</FormLabel>
                                                    <Input
                                                        {...input}
                                                        autoFocus={true}
                                                        placeholder={t("form_field__title_placeholder")}
                                                        error={(t(meta.error) || meta.submitError) && meta.touched}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                    <FormSpy subscription={{pristine: true}}>
                                        {props => (
                                            <Box
                                                sx={{
                                                    display: {
                                                        xs: props.pristine ? "none" : "block",
                                                        md: "block"
                                                    },
                                                    visibility: {
                                                        xs: "visible",
                                                        md: props.pristine ? "hidden" : "visible"
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        mt: 6,
                                                        mb: 4,
                                                        width: {sm: "100%", md: "50vw"},
                                                        display: "grid",
                                                        alignItems: "center",
                                                        justifyItems: "space-between",
                                                        gridTemplateColumns: {
                                                            sm: "1fr",
                                                            md: "4fr 4fr 5fr"
                                                        },
                                                        gridTemplateRows: {
                                                            sm: "2fr",
                                                            md: "1fr"
                                                        },
                                                        gap: 4
                                                    }}
                                                >
                                                    <Field name="dateFrom">
                                                        {({input, meta}) => (
                                                            <Box>
                                                                <JoyDatePicker
                                                                    label={t("form_field__date_from_label")}
                                                                    disablePast={true}
                                                                    value={parseISO(input.value)}
                                                                    onChange={(newValue: Date | null) => {
                                                                        setDateFrom(newValue);
                                                                        return (newValue === null)
                                                                            ? input.onChange(newValue)
                                                                            : input.onChange(format(newValue, "yyyy-MM-dd"))
                                                                    }}
                                                                />
                                                                {(t(meta.error) || meta.submitError) && meta.touched && (
                                                                    <Typography
                                                                        color="warning"
                                                                        variant="plain"
                                                                        level="body-sm"
                                                                        gutterBottom
                                                                    >
                                                                        {t(meta.error) || meta.submitError}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Field>
                                                    <Field name="dateTo">
                                                        {({input, meta}) => (
                                                            <Box>
                                                                <JoyDatePicker
                                                                    label={t("form_field__date_to_label")}
                                                                    shouldDisableDate={isInvalidDateTo}
                                                                    disablePast={true}
                                                                    value={parseISO(input.value)}
                                                                    onChange={(newValue: Date | null) => {
                                                                        return (newValue === null)
                                                                            ? input.onChange(newValue)
                                                                            : input.onChange(format(newValue, "yyyy-MM-dd"))
                                                                    }}
                                                                />
                                                                {(t(meta.error) || meta.submitError) && meta.touched && (
                                                                    <Typography
                                                                        color="warning"
                                                                        variant="plain"
                                                                        level="body-sm"
                                                                        gutterBottom
                                                                    >
                                                                        {t(meta.error) || meta.submitError}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Field>
                                                    <FormControl size="lg">
                                                        <FormLabel>{t("form_field__event_type_label")}</FormLabel>
                                                        <Field name="planId">
                                                            {({input, meta}) => (
                                                                <Select
                                                                    value={input.value}
                                                                    // onChange={(e) => {
                                                                    //     console.log(e)
                                                                    // }}
                                                                    placeholder={t("form_field__event_type_placeholder")}
                                                                    slotProps={{
                                                                        listbox: {
                                                                            component: "div",
                                                                            sx: {
                                                                                maxHeight: 240,
                                                                                overflow: "auto",
                                                                                "--List-padding": "0px",
                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{width: "100%"}}
                                                                >
                                                                    {Object.keys(plansByType).map((type: string, index) => (
                                                                        <Fragment key={index}>
                                                                            {index !== 0 && <ListDivider role="none"/>}
                                                                            <List
                                                                                aria-labelledby={`select-group-${type}`}
                                                                                sx={{"--ListItemDecorator-size": "28px"}}
                                                                            >
                                                                                <ListItem
                                                                                    id={`select-group-${type}`}
                                                                                    sticky
                                                                                >
                                                                                    <Typography
                                                                                        level="body-sm"
                                                                                        textTransform="uppercase"
                                                                                        letterSpacing="md"
                                                                                    >
                                                                                        {type}
                                                                                    </Typography>
                                                                                </ListItem>
                                                                                {/*@ts-ignore*/}
                                                                                {plansByType[type].map((plan: any) => (
                                                                                    <Option
                                                                                        onClick={(e) => {
                                                                                            setPlanId(plan.id)
                                                                                            input.onChange(plan.id);
                                                                                        }}
                                                                                        key={plan.id}
                                                                                        value={plan.id}
                                                                                        label={
                                                                                            <>
                                                                                                <Chip
                                                                                                    size="sm"
                                                                                                    color="neutral"
                                                                                                    sx={{
                                                                                                        borderRadius: "xs",
                                                                                                        mr: 1
                                                                                                    }}
                                                                                                >
                                                                                                    {type}
                                                                                                </Chip>{" "}
                                                                                                {plan.translations[lang].title}
                                                                                            </>
                                                                                        }
                                                                                        sx={{
                                                                                            [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]: {opacity: 1},
                                                                                            mx: 0.5,
                                                                                            my: 0.25,
                                                                                        }}
                                                                                    >
                                                                                        <>
                                                                                            <ListItemDecorator
                                                                                                sx={{opacity: 0}}>
                                                                                                <Check/>
                                                                                            </ListItemDecorator>
                                                                                            {plan.translations[lang].title}
                                                                                        </>
                                                                                    </Option>
                                                                                ))}
                                                                            </List>
                                                                        </Fragment>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                    <Button type="submit" color="success" size="lg"
                                                            endDecorator={<KeyboardArrowRight/>}>
                                                        {t("form_button__create_plan")}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </FormSpy>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Form>
                <Backdrop
                    sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={saving}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </ModalDialog>
        </ModalOverflow>
    )
}

export default PlanBuilderDialog
