import {Box, Divider, Grid, List, ModalClose, ModalDialog, ModalOverflow, Sheet, Skeleton, Typography} from "@mui/joy"

export const WorkoutCardLoading = () => (
    <Sheet
        variant="outlined"
        sx={{
            borderRadius: "sm",
            p: 1.5,
            mb: 1.5,
            width: "100%",
            cursor: "pointer"
        }}>
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 0.5
        }}>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Box sx={{display: "flex", fontSize: "1.1rem", flexDirection: "column", alignItems: "center"}}>
                    <Typography><Skeleton>XX</Skeleton></Typography>
                </Box>
                <Typography ml={0.5} noWrap level="body-sm">
                    <Skeleton>Cycling</Skeleton>
                </Typography>
            </Box>
        </Box>
        <Box mt={0.5} sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 0.5}}>
            <Typography noWrap fontSize="sm">
                <Skeleton>Lactate drift</Skeleton>
            </Typography>
        </Box>
        <Box mt={0.5} sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 0.5}}>
            <Typography noWrap level="body-md">
                <Skeleton>00:00:00</Skeleton>
            </Typography>
        </Box>
        <Box mt={2}>
            <Typography noWrap level="body-md">
                <Skeleton>TBD TBD TBD TBD TBD TBD TBD TBD TBD TBD TBD TBD TBD</Skeleton>
            </Typography>
        </Box>
    </Sheet>
)

export const AgendaWeekViewBodyLoading = () => (
    <Grid container spacing={1.5} columns={7} mt={0.5} sx={{width: "100%"}}>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
        <Grid xs>
            <List aria-labelledby="workouts">
                <WorkoutCardLoading/>
            </List>
        </Grid>
    </Grid>
)

export const AgendaDayViewBodyLoading = () => (
    <List sx={{p: 2}}>
        <WorkoutCardLoading/>
    </List>
)

export const AgendaWeekViewSidebarLoading = () => (
    <Box>
        <Box sx={{display: "grid", py: 2}}>
            <Typography pb={1} level="body-md"
                        textColor="text.tertiary"><Skeleton>Swimming</Skeleton></Typography>
            <Typography>
                <Skeleton>__________________________________</Skeleton>
            </Typography>
            <Typography mt={1} level="body-sm" textColor="text.tertiary">
                <Skeleton>00:00 / 00:00</Skeleton>
            </Typography>
        </Box>
        <Box sx={{display: "grid", py: 2}}>
            <Typography pb={1} level="body-md"
                        textColor="text.tertiary"><Skeleton>Cycling</Skeleton></Typography>
            <Typography>
                <Skeleton>__________________________________</Skeleton>
            </Typography>
            <Typography mt={1} level="body-sm" textColor="text.tertiary">
                <Skeleton>00:00 / 00:00</Skeleton>
            </Typography>
        </Box>
        <Box sx={{display: "grid", py: 2}}>
            <Typography pb={1} level="body-md"
                        textColor="text.tertiary"><Skeleton>Running</Skeleton></Typography>
            <Typography>
                <Skeleton>__________________________________</Skeleton>
            </Typography>
            <Typography mt={1} level="body-sm" textColor="text.tertiary">
                <Skeleton>00:00 / 00:00</Skeleton>
            </Typography>
        </Box>
    </Box>
)

export const ProfileDialogLoading = ({screen}: { screen?: "mobile" | "desktop" }) => (
    <ModalOverflow>
        <ModalDialog
            layout={screen === "mobile" ? "fullscreen" : "center"}
            color="neutral"
            size="lg"
            variant="outlined"
            role="alertdialog"
            aria-labelledby="alert-dialog-modal-title"
            aria-describedby="alert-dialog-modal-description"
            sx={{width: "50vw"}}
        >
            <ModalClose/>
            <Typography id="alert-dialog-modal-title" component="h2">
                <Skeleton>Profile</Skeleton>
            </Typography>
            <Divider/>
            <Box
                sx={{
                    py: 4,
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr",
                        lg: "2fr 2fr",
                    },
                    gap: 2.5
                }}
            >
                <Box>
                    <Typography><Skeleton>Full Name</Skeleton></Typography>
                    <Typography><Skeleton>Jan Frodeno.....................</Skeleton></Typography>
                </Box>
                <Box>
                    <Typography><Skeleton>Bith date</Skeleton></Typography>
                    <Typography><Skeleton>01.01.2023.....................</Skeleton></Typography>
                </Box>
                <Box>
                    <Typography><Skeleton>Height</Skeleton></Typography>
                    <Typography><Skeleton>190...................</Skeleton></Typography>
                </Box>
                <Box>
                    <Typography><Skeleton>Weight</Skeleton></Typography>
                    <Typography><Skeleton>90..........................................</Skeleton></Typography>
                </Box>
                <Box>
                    <Typography><Skeleton>Gender</Skeleton></Typography>
                    <Typography><Skeleton>Male.......................</Skeleton></Typography>
                    <Typography><Skeleton>Female.......................</Skeleton></Typography>
                </Box>
                <Box>
                    <Typography><Skeleton>Devices</Skeleton></Typography>
                    <Typography><Skeleton>Connect
                        Garmin..........................................</Skeleton></Typography>
                </Box>
            </Box>
        </ModalDialog>
    </ModalOverflow>
)

export const PlanBuilderDialogLoading = () => (
    <ModalOverflow>
        <ModalDialog
            layout="fullscreen"
            color="neutral"
            size="lg"
            variant="outlined"
            role="alertdialog"
            aria-labelledby="alert-dialog-modal-title"
            aria-describedby="alert-dialog-modal-description"
        >
            <ModalClose/>
            <Typography id="alert-dialog-modal-title" component="h2">
                <Skeleton>Create plan</Skeleton>
            </Typography>
            <Divider/>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                pt: {xs: 4, md: 0},
                pb: {md: 20}
            }}
            >
                <Box sx={{my: 2, width: {xs: "100%", md: "50vw"}}}>
                    <Box
                        sx={{
                            width: {sm: "100%", md: "50vw"},
                            display: "grid",
                            alignItems: "center",
                            justifyItems: "space-between",
                            gridTemplateColumns: {
                                sm: "1fr",
                            },
                            gap: 4
                        }}
                    >
                        <Typography><Skeleton>Event name</Skeleton></Typography>
                        <Typography><Skeleton>For instance, IRONMAN Barcelona...................</Skeleton></Typography>
                    </Box>
                </Box>
            </Box>
        </ModalDialog>
    </ModalOverflow>
)